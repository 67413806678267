/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles with performance optimizations */
html {
  touch-action: manipulation; /* Improves touch responsiveness */
  text-size-adjust: 100%; /* Prevents text size adjustment on orientation change */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none; /* Prevents pull-to-refresh on mobile */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  text-rendering: optimizeSpeed; /* Optimizes text rendering for speed */
}

/* Improve tap target sizes on mobile */
button, a, input, select, textarea {
  touch-action: manipulation;
}

/* Responsive image handling */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Responsive container for mobile-first design */
.container-responsive {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .container-responsive {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .container-responsive {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Optimize animations for reduced motion preference */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Animation classes for modals */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Mobile optimized animations */
@keyframes fadeInMobile {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-mobile {
  animation: fadeInMobile 0.2s ease-out forwards;
}

/* Hide scrollbar but allow scrolling */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Responsive typography */
.text-responsive {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
}

.heading-responsive {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
}

/* Optimize for touch devices */
@media (hover: none) {
  .touch-target {
    min-height: 44px;
    min-width: 44px;
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-nav {
  background-color: #f8f9fa;
  padding: 1rem;
}

.app-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.app-nav li {
  margin: 0 0.5rem;
}

.app-nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.app-main {
  flex-grow: 1;
  padding: 2rem;
}

.home {
  text-align: center;
}

.translation-options {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.option {
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  width: 25%;
  transition: transform 0.2s, box-shadow 0.2s;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .translation-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 80%;
    margin-bottom: 1rem;
  }
}

/* Add any other animation classes you need here */